<template>
  <div>
    <div class="card" v-bind:class="{ 'border-danger': hall.DaysBlocked > 0 }">
      <div class="card-body">
        <span
          class="badge badge-placement"
          v-bind:class="{
            'badge-success': hall.balance_str != 'Kortfeil - kontakt betjening',
            'badge-danger': hall.balance_str == 'Kortfeil - kontakt betjening',
          }"
          >{{
            hall.balance_str == "0" ? "Henter saldo..." : hall.balance_str
          }}</span
        >
        <div class="row" v-if="messages && messages.length > 0">
          <div class="container mx-0 px-0">
            <div v-bind:key="message.messageText" v-for="message in messages">
              <div v-if="message.messageHallID == hall.BingoID">
                <div
                  v-if="message.messageType == 'error'"
                  class="alert alert-danger text-white mb-2 mt-2"
                  role="alert"
                >
                  <strong>{{ message.messageHeader }}</strong>
                  {{ message.messageText }}
                </div>
                <div
                  v-else-if="message.messageType == 'warning'"
                  class="alert alert-warning text-white mb-2 mt-2"
                  role="alert"
                >
                  <strong>{{ message.messageHeader }}</strong>
                  {{ message.messageText }}
                </div>
                <div
                  v-else-if="message.messageType == 'info'"
                  class="alert alert-info text-white mb-2 mt-2"
                  role="alert"
                >
                  <strong>{{ message.messageHeader }}</strong>
                  {{ message.messageText }}
                </div>
                <div
                  v-else
                  class="alert alert-default text-white mb-2 mt-2"
                  role="alert"
                >
                  <strong>{{ message.messageHeader }}</strong>
                  {{ message.messageText }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-12 col-lg-2">
            <img
              v-if="hall.BingoLogo"
              :src="'/img/brand/logo/' + hall.BingoLogo"
            />
          </div>
          <div class="col-12 col-lg-5 pl-0">
            <div class="col-12 font-weight-bold">
              {{ hall.BingoName }}
            </div>
            <div class="col-12">
              {{ hall.BingoAddress }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6">
            <button
              v-if="
                hall.nets_clientid &&
                hall.balance_str &&
                hall.balance_str.includes('kr') &&
                hall.DaysBlocked < 1
              "
              type="button"
              @click="fillCard(hall)"
              class="btn m-2 btn-yellow btn-xl w-100"
            >
              Innskudd
            </button>
          </div>

          <div class="col-12 col-lg-6">
            <button
              v-if="!this.$store.state.noBankID && hall.BingoPayOut == 1"
              type="button"
              @click="showTicketBoxClick(hall)"
              class="btn m-2 btn-yellow btn-xl w-100"
            >
              Kjøpe billetter
            </button>
          </div>
          <div class="col-12 col-lg-6">
            <button
              v-if="!this.$store.state.noBankID && hall.BingoPayOut == 1"
              type="button"
              @click="showMyTicketsBoxClick(hall)"
              class="btn m-2 btn-yellow btn-xl w-100"
            >
              Mine billetter
            </button>
          </div>
          <div class="col-12 col-lg-6">
            <button
              v-if="
                hall.balance_str != 'Kortfeil - kontakt betjening' &&
                hall.showTransactions == 1
              "
              type="button"
              @click="openTransactionsBox(hall)"
              class="btn m-2 btn-yellow btn-xl w-100"
            >
              Transaksjoner
            </button>
          </div>
          <div class="col-12 col-lg-6">
            <button
              v-if="!this.$store.state.noBankID && hall.BingoPayOut == 1"
              type="button"
              @click="withdrawCard(hall)"
              class="btn m-2 btn-yellow btn-xl w-100"
            >
              Uttak
            </button>
          </div>

          <div class="col-12 mx-0 px-0" v-if="hall.DaysBlocked < 1 && false">
            <img
              v-if="hall.H1Active == '1'"
              src="img/brand/bingo_button.png"
              @click="playMainGame(hall)"
              class="m-2 float-right"
              style="cursor: pointer"
            />
            <img
              v-if="hall.H1Active == '0'"
              src="img/brand/h1_closed.png"
              class="m-2 float-right"
            />

            <img
              v-if="hall.H2Active == '1'"
              src="img/brand/h2_button.png"
              @click="playMainGame2(hall)"
              class="m-2 float-right"
              style="cursor: pointer"
            />
            <img
              v-if="hall.H2Active == '0'"
              src="img/brand/h2_closed.png"
              class="m-2 float-right"
            />
            <img
              v-if="hall.H3Active == '1'"
              src="img/brand/lynbingo.png"
              @click="playMainGame3(hall)"
              class="m-2 float-right"
              style="cursor: pointer"
            />
            <img
              v-if="hall.H3Active == '0'"
              src="img/brand/lynbingo_closed.png"
              class="m-2 float-right"
            />
            <img
              v-if="hall.ortiz == 'true'"
              src="img/brand/goalmania_button.png"
              @click="playRemoteMainGame(hall, 501, 'Goal Mania')"
              class="m-2 float-right"
              style="cursor: pointer"
            />
            <img
              v-if="false == true"
              src="img/brand/beachbingo_button.png"
              @click="playRemoteMainGame(hall, 503, 'Goal Mania')"
              class="m-2 float-right"
              style="cursor: pointer"
            />
          </div>
          <div
            class="col-12 mx-0 px-0 mt-2 float-right text-right"
            style="float: right"
            v-if="
              hall.H3Active == '0' &&
              hall.H2Active == '0' &&
              hall.H1Active == '0' &&
              messages &&
              messages.length == 0
            "
          >
            Spillene er stengt, og vil åpnes i bingosalongens åpningstid.
          </div>
          <div
            class="col-12 mx-0 px-0 mt-2 float-right text-right"
            style="float: right"
            v-else-if="
              (hall.H3Active == '0' ||
                hall.H2Active == '0' ||
                hall.H1Active == '0') &&
              messages &&
              messages.length == 0
            "
          >
            Et eller flere spill er stengt for dagen, spillene er åpne i
            bingosalongens åpningstid
          </div>
          <div
            class="col-12 mx-0 px-0 mt-2 text-danger"
            v-if="hall.DaysBlocked > 0"
          >
            Du har stengt seg ute i fra denne bingosalongen. Utestengelsen
            oppheves om {{ hall.DaysBlocked }} dager.
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Ny ticket -->
    <div v-show="showTicketBox">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Kjøpe billett</h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true" @click="showTicketBox = false"
                      >&times;</span
                    >
                  </button>
                </div>
                <div
                  class="modal-body"
                  style="overflow-y: auto; max-height: calc(100vh - 225px)"
                >
                  <div
                    v-if="buyTicketError != null"
                    class="alert alert-warning"
                    role="alert"
                  >
                    <strong>Oida!</strong>
                    {{ buyTicketError }}
                  </div>

                  <div
                    v-if="
                      !buyTicketSpinner &&
                      buyTicketData?.ticketNr?.length > 3 &&
                      buyStart
                    "
                  >
                    <!-- center content-->
                    <div class="text-center">
                      <p>Din billett er kjøpt!</p>
                      <h4>
                        {{ buyTicketData?.system == 1 ? "Aspelund" : "OK" }}
                      </h4>
                      <h2>{{ buyTicketData?.ticketNr }}</h2>
                      <h3>{{ buyTicketData?.currentBalance }} kr</h3>
                    </div>
                  </div>
                  <div v-else-if="buyTicketSpinner" class="text-center">
                    <i class="fa fa-spinner fa-pulse fa-2x fa-fw mt-3"></i>
                  </div>
                  <div v-else>
                    <p>Hvilket spill vil du kjøpe billett til?</p>
                    <div class="row">
                      <div
                        v-for="game in ticketGames"
                        :key="game.GameID"
                        class="col-12 col-lg-6 col-md-6 pb-3"
                      >
                        <button
                          @click="buyTicket(game.GameID)"
                          class="btn w-100"
                          :class="
                            buyTicketGameID == game.GameID
                              ? 'btn-success'
                              : 'btn-secondary'
                          "
                          :style="
                            buyTicketGameID == game.GameID
                              ? 'border: black solid 1px;'
                              : ''
                          "
                        >
                          {{ game.GameName }}
                        </button>
                      </div>
                    </div>
                    <div v-if="buyTicketGameID > 0">
                      Innskuddsbeløp (Saldo:
                      {{
                        hall.balance_str == "0"
                          ? "Henter saldo..."
                          : hall.balance_str
                      }})
                      <base-input
                        alternative
                        type="number"
                        name="ticketInnskudd"
                        id="ticketInnskudd"
                        autocomplete="one-time-code"
                        placeholder="Innskuddsbeløp"
                        addon-left-icon="ni ni-money-coins"
                        v-model="ticketInnskudd"
                        class="mt-2"
                        ref="inputRef"
                      >
                      </base-input>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <base-button
                    type="danger"
                    v-if="!buyTicketData"
                    @click="buyNewTicket"
                    :disabled="
                      buyTicketGameID == 0 ||
                      ticketInnskudd < 1 ||
                      buyTicketSpinner
                    "
                    >Kjøp ticket</base-button
                  >
                  <button
                    type="button"
                    class="btn btn-secondary"
                    @click="showTicketBox = false"
                  >
                    Lukk
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-show="showMyTicketsBox">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Mine billetter</h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true" @click="showMyTicketsBox = false"
                      >&times;</span
                    >
                  </button>
                </div>
                <div
                  class="modal-body"
                  style="overflow-y: auto; max-height: calc(100vh - 225px)"
                >
                  <div v-if="!myTicketsSpinner">
                    <div v-if="myTickets.length == 0" class="mb-2">
                      Ingen billetter funnet
                    </div>
                    <div
                      v-else
                      v-for="ticket in myTickets"
                      :key="ticket.ticketNr"
                      class="card mb-2"
                      :class="
                        ticket.statusText == 'Lukket' ? 'bg-secondary' : ''
                      "
                      style="width: 100%"
                    >
                      <div class="row mb-2 mt-2">
                        <div class="col-5">
                          <div class="col-12">
                            <div
                              class="pb-0 mb-0 no-wrap"
                              style="font-size: 1.5em"
                              :class="
                                ticket.statusText == 'Lukket'
                                  ? 'text-decoration-line-through'
                                  : ''
                              "
                            >
                              {{ ticket.ticketNr }}
                            </div>
                            <span class="badge bg-secondary">{{
                              ticket.systemName
                            }}</span>
                            <span class="badge badge-success"
                              >{{ ticket.balance }} kr</span
                            >
                          </div>
                        </div>
                        <div class="col-7" style="">
                          <div class="row">
                            <transition name="fade">
                              <div
                                class="alert alert-warning alert-dismissible fade show mr-4"
                                v-if="ticket.alert"
                                role="alert"
                              >
                                <strong>Oida!</strong> {{ ticket.alert }}
                                <button
                                  type="button"
                                  class="close"
                                  data-bs-dismiss="alert"
                                  aria-label="Lukk"
                                  @click="ticket.alert = null"
                                ></button>
                              </div>

                              <div
                                v-else-if="doClose == ticket"
                                class="col-12"
                                style="position: absolute"
                                key="close"
                              >
                                <div v-if="closeSpinner" class="text-center">
                                  <i
                                    class="fa fa-spinner fa-pulse fa-2x fa-fw mt-3"
                                  ></i>
                                </div>
                                <div v-else>
                                  <div
                                    class="float-right mr-2 w-100 text-right"
                                  >
                                    Lukke billetten?
                                  </div>

                                  <div
                                    class="btn-group btn-group-sm float-right mr-2 mt-1"
                                    role="group"
                                    aria-label="Basic mixed styles example"
                                  >
                                    <base-button
                                      type="button"
                                      class="btn btn-success"
                                      @click="submitCloseTicket(ticket)"
                                    >
                                      Lukk
                                    </base-button>
                                    <base-button
                                      icon="fa fa-times"
                                      type="button"
                                      style="width: 50px"
                                      class="btn btn-danger"
                                      @click="doClose = null"
                                    ></base-button>
                                  </div>
                                </div>
                              </div>
                              <div
                                v-else-if="doDeposit == ticket"
                                class="col-12"
                                style="position: absolute"
                                key="add"
                              >
                                <div v-if="depositSpinner" class="text-center">
                                  <i
                                    class="fa fa-spinner fa-pulse fa-2x fa-fw mt-3"
                                  ></i>
                                </div>
                                <div v-else>
                                  <div class="float-right mr-2 mb-2">
                                    Tast beløp for innskudd
                                  </div>

                                  <div
                                    class="input-group mb-3 input-group-sm pr-2"
                                  >
                                    <base-button
                                      class="btn btn-danger btn-sm"
                                      type="button"
                                      id="button-addon2"
                                      icon="fa fa-times"
                                      @click="doDeposit = null"
                                    >
                                    </base-button>
                                    <input
                                      :ref="'input-' + ticket.id"
                                      style="text-align: center"
                                      type="number"
                                      class="form-control"
                                      v-model="depositAmount"
                                      placeholder="Beløp.."
                                      aria-label="Beløp.."
                                      aria-describedby="button-addon2"
                                      autocomplete="one-time-code"
                                    />
                                    <base-button
                                      class="btn btn-success btn-sm"
                                      type="button"
                                      id="button-addon2"
                                      icon="fa fa-check"
                                      @click="submitTicketDeposit(ticket)"
                                      :disabled="depositAmount < 1"
                                    >
                                    </base-button>
                                  </div>
                                </div>
                              </div>
                              <div
                                v-else
                                class="col-12"
                                style="position: absolute"
                                key="index"
                              >
                                <div
                                  class="btn-group btn-group-sm float-right mr-2 mt-1"
                                  role="group"
                                  aria-label="Basic mixed styles example"
                                >
                                  <button
                                    type="button"
                                    class="btn btn-success"
                                    v-if="ticket.canDeposit"
                                    @click="chooseDeposit(ticket)"
                                  >
                                    Legg til kr
                                  </button>
                                  <button
                                    v-if="ticket.canClose"
                                    type="button"
                                    class="btn btn-danger"
                                    @click="doClose = ticket"
                                  >
                                    Lukk
                                  </button>
                                </div>
                              </div>
                            </transition>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div class="text-center">
                      <i
                        class="fa fa-spinner fa-pulse fa-2x fa-fw mt-2 mb-3"
                      ></i>
                    </div>
                  </div>

                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      @click="showMyTicketsBox = false"
                    >
                      Lukk
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import { Toast } from "bootstrap";
import axios from "../axios-auth";
import {
  addMoneyToTicket,
  myTickets,
  closeTicket,
  buyTicket,
} from "../wapFunctions";

export default {
  name: "bingo-page",
  data() {
    return {
      showTicketBox: false,
      showMyTicketsBox: false,
      doDeposit: false,
      depositAmount: null,
      depositSpinner: false,
      closeSpinner: false,
      doClose: null,
      ticketGames: [
        { GameID: 1, GameName: "Aspelund" },
        { GameID: 2, GameName: "OK Databingo" },
      ],
      myTickets: [],
      myTicketsSpinner: false,
      buyTicketGameID: 0,
      ticketInnskudd: null,
      buyTicketSpinner: false,
      buyTicketData: null,
      buyTicketError: null,
      buyStart: false,
    };
  },
  props: {
    hall: {
      type: Object,
      default: null,
      description: "Bingo Data",
      balance: null,
      balance_str: null,
    },
    messages: {
      type: Array,
      default: null,
      description: "Messages for bingo",
    },
  },
  mounted() {
    document.addEventListener("visibilitychange", this.handleVisibilityChange);
  },
  beforeDestroy() {
    document.removeEventListener(
      "visibilitychange",
      this.handleVisibilityChange
    );
  },

  methods: {
    OpenBingoPage(hall) {
      console.log("OpenBingoPage(" + hall.BingoID + ")");
      // navigate to bingo page route mybingo/hall.BingoID and change querystring to /mybingo/hall.BingoID
      this.$router.push({ name: "mybingo", params: { id: hall.BingoID } });
    },
    fillCard(hall) {
      console.log("fillCard(" + hall.BingoID + ")");
      this.$emit("openCard", hall);
    },
    handleVisibilityChange() {
      if (document.visibilityState === "hidden") {
        this.lastHiddenTime = Date.now();
      } else if (document.visibilityState === "visible") {
        const now = Date.now();
        if (this.lastHiddenTime && now - this.lastHiddenTime > 10000) {
          // 5 seconds threshold
          this.getHallBalance(this.hall);
        }
      }
    },

    withdrawCard(hall) {
      console.log("withdrawCard(" + hall.BingoID + ")");
      this.$emit("withdrawCard", hall);
    },
    playMainGame(hall) {
      console.log("playMainGame(" + hall.BingoID + ")");
      this.$emit("playMainGame", hall);
    },
    playMainGame2(hall) {
      console.log("playMainGame2(" + hall.BingoID + ")");
      this.$emit("playMainGame2", hall);
    },
    playMainGame3(hall) {
      console.log("playMainGame3(" + hall.BingoID + ")");
      this.$emit("playMainGame3", hall);
    },
    playRemoteMainGame(hall, gameid, gameName = "") {
      console.log("playRemoteMainGame(" + hall.BingoID + ")");
      this.$emit("playRemoteMainGame", hall, gameid, gameName);
    },
    openTransactionsBox(hall) {
      console.log("openTransactionsBox(" + hall.BingoID + ")");
      this.$emit("openTransactionsBox", hall);
    },
    showTicketBoxClick() {
      this.ticketInnskudd = null;
      this.buyTicketGameID = 0;
      this.buyTicketSpinner = false;
      this.buyTicketData = null;
      this.showTicketBox = true;
      this.buyStart = false;
      this.buyTicketError = null;
    },
    chooseDeposit(ticket) {
      this.doDeposit = ticket;
      this.depositAmount = null;
      this.$nextTick(() => {
        const refName = `input-${ticket.id}`;
        if (this.$refs[refName]) {
          this.$refs[refName][0].focus();
        } else {
          console.error(
            `Ref ${refName} not found or inputField ref is not set correctly`
          );
        }
      });
    },
    async showMyTicketsBoxClick() {
      // get tickets from wapFunctions.js

      this.showMyTicketsBox = true;

      this.myTicketsSpinner = true;
      this.tickets = await myTickets(
        this.hall.BingoID,
        this.$store.state.idToken
      );
      this.myTickets = this.tickets;
      this.myTicketsSpinner = false;
    },
    buyTicket(gameid) {
      this.buyTicketGameID = gameid;

      this.$nextTick(() => {
        if (this.$refs.inputRef && this.$refs.inputRef.$refs.inputField) {
          this.$refs.inputRef.$refs.inputField.focus();
        } else {
          console.error("inputRef or inputField ref is not set correctly");
        }
      });
    },
    // fake function to simulate ticket add balance
    async submitTicketDeposit(ticket) {
      this.depositSpinner = true;

      let data = await addMoneyToTicket(
        this.hall.BingoID,
        ticket.system,
        ticket.ticketNr,
        this.depositAmount,
        this.$store.state.idToken
      );
      console.log(data);
      // Hvis feilmelding i data?.addMoneyResponse?.errorText vis feilmelding, hvis data?.addMoneyResponse = '' så skriv en generisk feilmelding
      if (
        data?.addMoneyResponse?.errorText?.length > 2 ||
        data?.addMoneyResponse == ""
      ) {
        this.triggerAlert(
          ticket,
          data?.addMoneyResponse?.errorText?.length > 2
            ? data?.addMoneyResponse?.errorText
            : "Det skjedde en feil ved innskudd"
        );
      } else if (data?.addMoneyResponse?.currentBalance) {
        ticket.balance = data?.addMoneyResponse?.currentBalance;
      }

      this.depositAmount = null;
      this.doDeposit = null;
      this.depositSpinner = false;
      this.delayedBalance(this.hall);
    },
    triggerAlert(ticket, alertText) {
      ticket.alert = alertText;
      console.log("Start time:", new Date().toISOString());
      setTimeout(() => {
        ticket.alert = null;
        console.log("End time:", new Date().toISOString());
      }, 3000);
    },
    async submitCloseTicket(ticket) {
      this.closeSpinner = true;
      console.log("submitCloseTicket()");
      let closeData = await closeTicket(
        this.hall.BingoID,
        ticket.system,
        ticket.ticketNr,
        this.$store.state.idToken
      );

      console.log(closeData);

      if (
        closeData?.closeTicketResponse?.currentBalance == 0 &&
        closeData?.closeTicketResponse?.errorText == ""
      ) {
        ticket.statusText = "Lukket";
        ticket.canClose = false;
        ticket.canDeposit = false;
        ticket.balance = 0;
      } else {
        console.log("Error closing ticket");

        this.triggerAlert(
          ticket,
          closeData?.closeTicketResponse?.errorText?.length > 2
            ? closeData?.closeTicketResponse?.errorText
            : "Det skjedde en feil ved lukking"
        );
      }

      this.doClose = null;
      this.closeSpinner = false;

      this.delayedBalance(this.hall);
    },
    async buyNewTicket() {
      console.log("buyNewTicket()");
      this.buyTicketSpinner = true;
      this.buyStart = true;
      let data = await buyTicket(
        this.hall.BingoID,
        this.buyTicketGameID,
        this.ticketInnskudd,
        this.$store.state.idToken
      );

      if (data?.buyTicketResponseData?.errorText?.length > 2) {
        this.buyTicketError = data?.buyTicketResponseData?.errorText;
      }
      if (data?.buyTicketResponseData == "") {
        this.buyTicketError = "Det skjedde en feil ved kjøp av billett";
      }
      setTimeout(() => {
        this.buyTicketError = null;
      }, 5000);

      console.log(data);
      this.buyTicketData = data?.buyTicketResponseData;
      this.buyTicketSpinner = false;

      this.delayedBalance(this.hall);
    },

    delayedBalance(hall) {
      setTimeout(() => {
        this.getHallBalance(hall, 0);
      }, 500);
    },
    getHallBalance(hall, attempt = 0) {
      axios
        .post(
          "/banking/balance",
          { bingoid: hall.BingoID },
          {
            headers: { Authorization: "Bearer " + this.$store.state.idToken },
          }
        )
        .then((res) => {
          if (isNaN(res.data.balance)) {
            hall.balance_str = "Kortfeil - kontakt betjening";
            hall.balance = 0;
          } else {
            const newBalanceStr =
              "kr " + new Intl.NumberFormat().format(res.data.balance);
            if (hall.balance_str !== newBalanceStr && attempt < 3) {
              hall.balance_str = newBalanceStr;
              hall.balance = res.data.balance;
            } else if (attempt < 3) {
              setTimeout(() => {
                this.getHallBalance(hall, attempt + 1);
              }, 1000);
            }
          }

          console.log(res.data);
        })
        .catch((error) => {
          console.error("Error fetching balance:", error);
          if (attempt < 3) {
            setTimeout(() => {
              this.getHallBalance(hall, attempt + 1);
            }, 1000);
          }
        });
    },
  },
};
</script>

<style scoped>
.badge-placement {
  position: absolute;
  top: 2px;
  right: 2px;
  font-size: large;
  padding: 5px 5px;
  color: black;
  text-transform: initial;
}
.modal-mask {
  position: fixed;
  z-index: 9997;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-dialog {
  z-index: 9999;
  max-width: 550px !important;
}
.modal-wrapper {
  z-index: 9998;
  display: table-cell;
  vertical-align: middle;
}
.modal-xl {
  z-index: 9999;
  max-width: 1200px !important;
  height: 98% !important;
}
.modal-xl-h {
  height: 100% !important;
}
.modal-xl-h-90 {
  display: block !important;
  height: 90% !important;
}
.text-decoration-line-through {
  text-decoration: line-through;
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.no-wrap {
  white-space: nowrap;
}
</style>